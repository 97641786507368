<!-- 설비 정보 관리 메인 -->
<template>
  <div class="ld-container" style="height: 90vh; width: 100%">
    <div class="row" style="height: 100%">
      <div class="list-area col-md-6">
        <div class="row">
          <HeaderBox
            :title="`${$t('운영 기준정보 관리')} > ${$t(
              `${
                $route.params.type == 'energy'
                  ? '단위 부하 관리'
                  : '단위 설비 관리'
              }`
            )}`"
            :goBack="true"
          />
        </div>
        <div style="height: calc(100% - 40px)">
          <TableList
            ref="TableList"
            :title="title"
            :columns="columns"
            :rows="equipInfoList"
            :keyField="'equipIdx'"
            :groupField="'deviceName'"
            :toggleFields="['enabled']"
            :transCodes="transCodes"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="true"
            @button-click="handleListButtonClick"
            @row-click="handleItemClick"
            @toggle-changed="handleItemToggle"
          />
        </div>
      </div>
      <div class="detail-area col-md-6">
        <EquipInfoDetail
          ref="EquipInfoDetail"
          :title="'설비 인벤토리'"
          :equipIdx="equipIdx"
          :equipType="equipType"
          @data-updated="handleDetailUpdated"
          @closed="handleDetailClosed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

import EquipInfoDetail from "./EquipInfoDetail.vue";
import backEndApi from "@src/api/backEndApi";
// import moment from "moment";

export default {
  components: {
    HeaderBox,
    TableList,
    EquipInfoDetail,
  },
  props: {},
  data() {
    return {
      equipType: null,
      equipTypeInfo: null,
      equipInfoList: null,
      equipIdx: null,
      controls: [
        { event: "delete", label: "삭제", class: "btn-danger" },
        { event: "refresh", label: "갱신", class: "bg-white text-dark" },
        {
          event: "status",
          label: "사용",
          class: "bg-white text-dark",
          options: { enabled: "Y" },
        },
        {
          event: "status",
          label: "미사용",
          class: "bg-white text-dark",
          options: { enabled: "N" },
        },
        { event: "regist", label: "추가", class: "btn-primary" },
      ],
      transCodes: [
        {
          field: "deviceName",
        },
        {
          field: "equipCategory",
          codes: this.$store.state.commonCodes.equipCategory,
        },
        {
          field: "equipUseType",
          codes: this.$store.state.commonCodes.phscPointUsage,
        },
        {
          field: "zoneIdx",
          codes: this.$store.state.zoneInfo,
        },
        {
          field: "enabled",
          codes: this.$store.state.commonCodes.enabled,
        },
      ],
      columns: [
        // 테이블 column
        {
          label: this.$t("설비명"),
          field: "rowTitle",
          type: "text",
          compositionFields: ["equipName", "equipIdx"],
        },
        {
          label: this.$t("연동 장치"),
          field: "deviceName",
          type: "text",
          compositionFields: ["deviceName", "deviceIdx"],
        },
        {
          label: this.$t("장치 모듈"),
          field: "moduleName2",
          type: "text",
          compositionFields: ["moduleName", "moduleIdx"],
        },
        {
          label: this.$t("설비 분류"),
          field: "equipCategory",
          type: "text",
        },
        {
          label: this.$t("설비 용도"),
          field: "equipUseType",
          type: "text",
        },
        {
          label: this.$t("구역"),
          field: "zoneIdx",
          type: "text",
        },
        {
          label: this.$t("사용여부"),
          field: "enabled",
          width: "100px",
          type: "text",
        },
      ],
    };
  },
  computed: {
    title() {
      try {
        return `${this.equipTypeInfo.equipTypeName} (${this.equipType})`;
      } catch (err) {
        return "";
      }
    },
  },
  created() {
    this.equipType = this.$route.query.equipType;
    this.getDataList();

    const result = this.$store.state.equipTypeList.find(
      (item) => item.equipType == this.equipType
    );
    this.equipTypeInfo = { ...result };
  },
  watch: {
    $route(to) {
      if (to.query.equipType) {
        this.equipType = to.query.equipType;
      }
    },
  },
  mounted() {},
  methods: {
    async handleListButtonClick(event, options) {
      switch (event) {
        case "regist":
          await this.registNew();
          break;

        case "delete":
          await this.deleteChecked();
          break;

        case "refresh":
          await this.getDataList(event);
          break;

        case "status":
          await this.changeStatusChecked(options);
          break;

        default:
          console.warn(
            "Unhandled Component Event. event:",
            event,
            "options:",
            options
          );
          break;
      }
    },

    async handleItemToggle(key, field, status) {
      const that = this;

      await that.changeStatus(key, field, status);
    },

    async handleItemClick(row, idx, toggle, check) {
      const that = this;

      if (toggle || check) return;

      const confirm = await that.$refs["EquipInfoDetail"].notifyCancelEdit();
      if (confirm) {
        this.equipIdx = row.equipIdx;
        this.$nextTick(() => {
          that.$refs["EquipInfoDetail"].loadData();
        });
      }

      // const confirm = await that.$refs['EquipInfoDetail'].notifyCancelEdit();
      // if (confirm) {
      //     let result = await backEndApi.equips.getEquipInfo(row.equipIdx, 'Y');
      //     if (result.data) {
      //         // await backEndApi.equips.getModuleList(result.data.equipType, "Y", result.data.moduleIdx);
      //         if (row.moduleIdx) {
      //             const equipList = await backEndApi.equipTypes.getEquipType(row.equipType);
      //             // const moduleProps = await backEndApi.equips.getModulePropList(row.moduleIdx);
      //             result.data.ioProps.forEach(ioProp => {
      //                 let foundEquip = equipList.data.ioProps.find(item => item.propCode === ioProp.propCode)
      //                 // let foundModule = moduleProps.data.find(item => item.ptIdx === ioProp.ptIdx)
      //                 if (foundEquip) {
      //                     this.$set(ioProp, "propName", foundEquip.propName);
      //                     this.$set(ioProp, "unitType", foundEquip.unitType);
      //                     this.$set(ioProp, "unit", foundEquip.unit);

      //                 }
      //                 // if(foundModule) {
      //                 //     this.$set(ioProp, "ptAddr", foundModule.ptAddr);
      //                 // }
      //             })
      //             result.data.calcProps.forEach(calcProp => {
      //                 let foundEquip = equipList.data.calcProps.find(item => item.propCode === calcProp.propCode)
      //                 // let foundModule = moduleProps.data.find(item => item.ptIdx === calcProp.ptIdx)
      //                 if (foundEquip) {
      //                     this.$set(calcProp, "propName", foundEquip.propName);
      //                     this.$set(calcProp, "unitType", foundEquip.unitType);
      //                     this.$set(calcProp, "unit", foundEquip.unit);
      //                     this.$set(calcProp, "expression", foundEquip.expression);
      //                 }
      //                 // if(foundModule) {
      //                 //     this.$set(calcProp, "ptAddr", foundModule.ptAddr);
      //                 // }
      //             })
      //             result.data.userProps.forEach(userProp => {
      //                 let foundEquip = equipList.data.userProps.find(item => item.propCode === userProp.propCode)
      //                 // let foundModule = moduleProps.data.find(item => item.ptIdx === userProp.ptIdx)
      //                 if (foundEquip) {
      //                     this.$set(userProp, "propName", foundEquip.propName);
      //                     this.$set(userProp, "unitType", foundEquip.unitType);
      //                     this.$set(userProp, "unit", foundEquip.unit);
      //                     this.$set(userProp, "expression", foundEquip.expression);
      //                 }
      //                 // if(foundModule) {
      //                 //     this.$set(userProp, "ptAddr", foundModule.ptAddr);
      //                 // }
      //             })
      //             result.data.regDate = result.data.regDate ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss") : null;
      //             result.data.modDate = result.data.modDate ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss") : null;
      //             await that.$refs['EquipInfoDetail'].getModuleList(result.data.equipType, row.moduleIdx, row.deviceIdx);
      //             await that.$refs['EquipInfoDetail'].setData(result.data);
      //         } else {
      //             result.data.regDate = result.data.regDate ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss") : null;
      //             result.data.modDate = result.data.modDate ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss") : null;
      //             await that.$refs['EquipInfoDetail'].getModuleList(result.data.equipType);
      //             await that.$refs['EquipInfoDetail'].setData(result.data);
      //         }
      //     }
    },

    /**
     * Table List Management Functions
     */
    async getDataList(event) {
      const that = this;
      try {
        let result = await backEndApi.equips.getEquipList(that.equipType);
        if (result.data) {
          that.equipInfoList = result.data;
          if (event) await this.alertNoti("갱신되었습니다.");
        }
        return result;
      } catch (e) {
        console.error(e);
        // this.alertNoti(e);
      }
    },

    async deleteChecked() {
      const that = this;

      try {
        let confirm = await this.alertConfirmWarning(
          "선택한 목록을 삭제하시겠습니까?"
        );
        if (!confirm.value) return;

        const checked = this.$refs["TableList"].getCheckedRows();
        if (!that.isEmpty(checked)) {
          checked.forEach(async (item) => {
            await backEndApi.equips.deleteEquipInfo(item.equipIdx);
          });
          await this.alertNoti("선택한 목록을 삭제하였습니다.");
          let clear = await that.getDataList();
          if (clear) await that.$refs["EquipInfoDetail"].clearData();
          else {
            that.equipInfoList = null;
            await that.$refs["EquipInfoDetail"].clearData();
          }
        } else {
          this.alertNoti("목록을 선택해주세요.");
        }
      } catch (e) {
        this.alertNoti("삭제에 실패하였습니다.");
        console.error(e);
      }
    },

    async changeStatusChecked(options) {
      const that = this;

      let data = options;

      try {
        const checked = this.$refs["TableList"].getCheckedRows();
        if (!that.isEmpty(checked)) {
          checked.forEach(async (item) => {
            if (!that.isEmpty(data)) {
              data["equipIdx"] = item.equipIdx;

              if (data.hasOwnProperty("enabled")) {
                await backEndApi.equips.setEquipEnabled(data);
              } else {
                console.warn("Unknown Status Name");
              }
            }
          });
          await this.alertNoti("변경하였습니다.");
          await that.getDataList();
        } else {
          this.alertNoti("목록을 선택해주세요.");
        }
      } catch (e) {
        console.error(e);
        this.alertNoti("상태 변경에 실패하였습니다.");
      }
    },

    async changeStatus(key, field, status) {
      const data = {};
      data["equipIdx"] = key;
      data[field] = status;

      try {
        let result = await backEndApi.equips.setEquipEnabled(data);
        if (result.data) {
          await this.alertNoti("변경하였습니다.");
          await this.getDataList();
        }
      } catch (e) {
        console.error(e);
        this.alertNoti("상태 변경에 실패하였습니다.");
      }
    },

    async registNew() {
      this.$refs["EquipInfoDetail"].newData();
    },

    async handleDetailUpdated(type, data) {
      console.log(type);
      console.log(data);
      await this.getDataList();

      // if (this.isEmpty(data.equipType)) {
      //     this.alertNoti("설비 유형 코드는 필수 입력 항목입니다.");
      //     return;
      // }
      // data.equipIdx = Number(data.equipIdx);
      // try {
      //     let result = await backEndApi.equips.saveEquipType(data);
      //     if (result.data) {
      //         await this.alertNoti("저장하였습니다.");
      //         await this.getDataList();
      //         await this.$refs['EquipInfoDetail'].clearData();
      //     }
      // } catch (e) {
      //     this.alertNoti("저장에 실패하였습니다.");
      //     console.error(e);
      // }
    },

    handleDetailClosed() {
      this.commonCode = null;
    },
  },
};
</script>

<style scoped>
.ld-container {
  height: 90vh;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

  padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
  height: 100%;
  overflow: hidden;
  padding: 10px;
  /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-wrap: nowrap; */
  /* flex: 1; */

  height: 100%;
  margin-right: 0px;
  padding: 0px 10px 0px 10px;

  border-radius: 10px;
  border-top: 1px solid #f4f4f4;
  border-right: 2px solid #e7e7e7;
  border-bottom: 2px solid #e7e7e7;
  border-left: 1px solid #f4f4f4;
  background-color: #f9f9f9;
}

.badge {
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
  display: inline-block;
  min-width: 20px;
  padding: 0px 10px 0px 10px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  /* background-color: #777; */
  line-height: 22px;
  border-radius: 8px;
}

.badge.title1 {
  background-color: #ff6d00;
  color: #fff;
}

.badge.title2 {
  background-color: #546e7a;
  color: #fff;
}

.badge.others {
  background-color: #cfd8dc;
  color: #000;
}
</style>