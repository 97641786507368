var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ld-container",staticStyle:{"height":"90vh","width":"100%"}},[_c('div',{staticClass:"row",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"list-area col-md-6"},[_c('div',{staticClass:"row"},[_c('HeaderBox',{attrs:{"title":`${_vm.$t('운영 기준정보 관리')} > ${_vm.$t(
            `${
              _vm.$route.params.type == 'energy'
                ? '단위 부하 관리'
                : '단위 설비 관리'
            }`
          )}`,"goBack":true}})],1),_c('div',{staticStyle:{"height":"calc(100% - 40px)"}},[_c('TableList',{ref:"TableList",attrs:{"title":_vm.title,"columns":_vm.columns,"rows":_vm.equipInfoList,"keyField":'equipIdx',"groupField":'deviceName',"toggleFields":['enabled'],"transCodes":_vm.transCodes,"controls":_vm.controls,"mode":'edit',"useMaxHeight":true},on:{"button-click":_vm.handleListButtonClick,"row-click":_vm.handleItemClick,"toggle-changed":_vm.handleItemToggle}})],1)]),_c('div',{staticClass:"detail-area col-md-6"},[_c('EquipInfoDetail',{ref:"EquipInfoDetail",attrs:{"title":'설비 인벤토리',"equipIdx":_vm.equipIdx,"equipType":_vm.equipType},on:{"data-updated":_vm.handleDetailUpdated,"closed":_vm.handleDetailClosed}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }